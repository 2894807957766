import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import messagesDe from './locale/da.json';
import messagesEn from './locale/en.json';
import messagesFr from './locale/fr.json';
import messagesIt from './locale/it.json';
import messagesNo from './locale/no.json';
import messagesPl from './locale/pl.json';
import messagesSv from './locale/sv.json';
import messagesZh from './locale/zh.json';
import Error from './components/common/Error';

const App = () => {
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const languageState = useSelector((state) => state.customizer.LanguageState);
  const messages = {
    en: messagesEn,
    de: messagesDe,
    fr: messagesFr,
    it: messagesIt,
    no: messagesNo,
    pl: messagesPl,
    sv: messagesSv,
    zh: messagesZh,
  };
  // const language =  languageState;

  return (
    <>
      <ErrorBoundary FallbackComponent={Error}>
        <Suspense fallback={<Loader />}>
          <IntlProvider
            langauge={languageState}
            messages={messages[languageState]}
            locale={languageState}
            defaultLocale="en"
          >
            <div
              className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
              dir={direction ? 'rtl' : 'ltr'}
            >
              <ThemeSelector />
              {routing}
            </div>
          </IntlProvider>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default App;
