import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './Error.scss';
// import { Button } from 'reactstrap';
import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/iCoordinator-logo_transp.svg';
import errorBg from '../../assets/images/background/error-bg.jpg';
import ReportFeedbackModal from './ReportFeedbackModal';

const Error = () => {
  const [report, setReport] = useState(false);
  const navigate = useNavigate();
  const toggleReportModal = () => {
    setReport(!report);
  };
  const navigateHome = () => {
    navigate('/home');
  };
  return (
    <>
      <div
        className="loginBox"
        style={{ background: `url(${errorBg}) no-repeat bottom center #fff` }}
      >
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center d-flex flex-column align-items-center justify-content-center">
            <div className="my-3 d-flex flex-column align-items-center justify-content-center">
              <LogoWhiteIcon />
              <h1 className="mt-3">
                <FormattedMessage
                  id="Application_is_not_available_right_now"
                  defaultMessage="Application_is_not_available_right_now"
                />
              </h1>
              <span className="text-muted d-block fs-5">Please try to Reload the application</span>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <h3>
                <a onClick={navigateHome} rel="noreferrer">
                  <FormattedMessage id="clickherereload" defaultMessage="Click here Reload" />
                </a>
              </h3>
              {/* <Button onClick={resetErrorBoundary} className="btn btn-danger">
                <FormattedMessage id="Reload" defaultMessage="Reload"/>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      {report ? <ReportFeedbackModal modalStatus={report} toggle={toggleReportModal} /> : ''}
    </>
  );
};
// Error.propTypes = {
//   error : PropTypes.any,
//   resetErrorBoundary : PropTypes.any
// };
export default Error;
