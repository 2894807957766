/* eslint-disable no-unused-vars */
import { call, put, take, takeEvery, select, all, takeLatest } from 'redux-saga/effects';
import * as cutomiser from '../store/customizer/CustomizerSlice';
import * as common from '../store/common/CommonSlice';
import { Commonservice } from '../service/commonService';
import config from '../config/config';
import { getSliceData } from '../store/customizer/CustomizerSlice';
import { errorCode, getFromLocalStorage, setTokenLocally, sortNameArrayFilter } from '../helpers/commonHelpers';
// import { errorCode, findWorkspaceDetails,downloadMultiple, sortNameArrayFilter, sortArray } from '../helpers/commonHelpers';
// import { findObjects, makeObjectsExtensible, makeObjectsExtensibleDynamic, makeObjectsTraversing } from '../hooks/utilities';
// import { findId } from '../hooks/utilities';

const url = `http://${window?.location?.host}`;

function* getSearchPermission(action) {
  const { folderId } = action.payload;
  try {
    const response = yield Commonservice.checkPermissionSearch(folderId);
    if (response.data) {
      yield put(common.updateSearchPermission(response.data));
    }
  } catch (e) {
    yield put(common.updateCommonAlert({ state: true, errorCode: e?.response?.status }));
  }
}

function* getFavFolderItems(action) {
  const { portalId } = action.payload;
  yield put(cutomiser.updateServerLoader(true));
  try {
    const response = yield Commonservice.getFavFolders(portalId, 'folder');
    if (response?.data?.entries) {
      yield put(cutomiser.rebasedFavFolder(response.data.entries));
    }
    yield put(cutomiser.updateServerLoader(false));
  } catch (e) {
    yield put(cutomiser.updateServerLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Folder',
          body: 'fav_folder_error',
          defaultMessage: 'Unable to update fav folder.',
        },
      }),
    );
    console.log('infavFolder error', e);
  }
}

function* postFavFolders(action) {
  const { portalId, favFolders } = action.payload;
  yield put(cutomiser.updateServerLoader(true));
  try {
    const response = yield Commonservice.postFavFolder(portalId, favFolders, 'folder');
    if (response) {
      yield put(common.updateFavFolderGet({ portalId }));
    }
    yield put(cutomiser.updateServerLoader(false));
  } catch (e) {
    yield put(cutomiser.updateServerLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Folder',
          body: 'fav_folder_error',
          defaultMessage: 'Unable to update fav folder.',
        },
      }),
    );
    console.log(e);
  }
}

function* sendDeleteFavs(action) {
  const { portalId, favs, type } = action.payload;
  if (type === 'folder') {
    yield put(cutomiser.updateServerLoader(true));
  } else if (type === 'smart_folder') {
    yield put(cutomiser.updateHomesmartFolderLoader(true));
  }
  try {
    const response = yield Commonservice.deleteFavs(portalId, favs);
    if (response) {
      if (type === 'folder') {
        yield put(common.updateFavFolderGet({ portalId }));
      } else if (type === 'smart_folder') {
        yield put(common.updateFavSmartFolderGet({ portalId }));
      }
    }
    if (type === 'folder') {
      yield put(cutomiser.updateServerLoader(false));
    } else if (type === 'smart_folder') {
      yield put(cutomiser.updateHomesmartFolderLoader(false));
    }
  } catch (e) {
    if (type === 'folder') {
      yield put(cutomiser.updateServerLoader(false));
    } else if (type === 'smart_folder') {
      yield put(cutomiser.updateHomesmartFolderLoader(false));
    }
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Folder',
          body: 'fav_folder_error',
          defaultMessage: 'Unable to update fav folder.',
        },
      }),
    );
    console.log('e', e);
  }
}

function* postFavSmartFolders(action) {
  const { portalId, favFolders, deleteItem } = action.payload;
  yield put(cutomiser.updateHomesmartFolderLoader(true));
  try {
    const response = yield Commonservice.postFavFolder(portalId, favFolders, 'smart_folder');
    if (response && deleteItem?.length === 0) {
      yield put(common.updateFavSmartFolderGet({ portalId }));
    }
    yield put(cutomiser.updateHomesmartFolderLoader(false));
  } catch (e) {
    yield put(cutomiser.updateHomesmartFolderLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'smartfolder_fav',
          body: 'fav_smart_folder_error',
          defaultMessage: 'Unable to update fav smart folder.',
        },
      }),
    );
    console.log(e);
  }
}

function* getFavSmartFolderItems(action) {
  const { portalId } = action.payload;
  let workpsacesSmartfolder = [];
  yield put(cutomiser.updateHomesmartFolderLoader(true));
  try {
    const responseWOrkspace = yield Commonservice.getPortalWorkspaces(portalId);
    workpsacesSmartfolder = responseWOrkspace?.data?.entries?.map((item) => {
      return {
        id: item.id,
        type: 'smart_folder',
        key: `workspace`,
        name: item.name,
        url: '/user/drive/smartfolder',
        data: `Files/Workspaces/${item.name}`,
        icon: 'Archive',
        pivot: `44/${item.id}`,
        Nodes: [],
      };
    });
    const dataSet = sortNameArrayFilter(workpsacesSmartfolder);
    yield put(cutomiser.updateSmartFolderNodes([...dataSet]));
    try {
      const response = yield Commonservice.getFavFolders(portalId, 'smart_folder');
      if (response?.data?.entries) {
        yield put(common.rebasedSmartFolder(response.data.entries));
      }
      yield put(cutomiser.updateHomesmartFolderLoader(false));
    } catch (e) {
      yield put(cutomiser.updateHomesmartFolderLoader(false));
      yield put(
        cutomiser.updateDriveError({
          status: true,
          message: {
            head: 'smartfolder_fav',
            body: 'fav_smart_folder_error',
            defaultMessage: 'Unable to update fav smart folder.',
          },
        }),
      );
    }
  } catch (e) {
    console.log('fav smart folder error>>>>>>', e);
    yield put(cutomiser.updateHomesmartFolderLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'smartfolder_fav',
          body: 'fav_smart_folder_error',
          defaultMessage: 'Unable to update fav smart folder.',
        },
      }),
    );
  }
}

function* notficationPopupFunction() {
  try {
    yield put(common.updateNotificationLoader(true));
    const response = yield Commonservice.getNotifications();
    yield put(common.updateNotificationState(response?.data));
    yield put(common.updateNotificationLoader(false));
  } catch (e) {
    yield put(common.updateNotificationLoader(false));
    console.log('e', e);
  }
}

function* markAllAsReadNotifications() {
  try {
    try {
      yield put(common.updateNotificationLoader(true));
      yield Commonservice.readNotifications();
      const response = yield Commonservice.getNotifications();
      yield put(common.updateNotificationState(response?.data));
      yield put(common.updateNotificationLoader(false));
    } catch (e) {
      yield put(common.updateNotificationLoader(false));
      const error = errorCode(e?.response?.status);
      yield put(
        cutomiser.updateDriveError({
          status: true,
          message: { head: 'Operation failed.', body: error, defaultMessage: 'Operation failed..' },
        }),
      );
    }
  } catch (e) {
    yield put(cutomiser.updateCommonLoader(false));
    const error = errorCode(e?.response?.status);
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: { head: 'Operation failed.', body: error, defaultMessage: 'Operation failed..' },
      }),
    );
  }
}

function* fetchItemPath(action) {
  const { itemId, type } = action.payload;
  try {
    const response = yield Commonservice.getFileFolderPathEndpoint(itemId, type);
    if (response) {
      yield put(common.updateItemPath(response));
    }
  } catch (e) {
    console.log('e>>>>', e);
  }
}

function* clearAllNotifications() {
  try {
    try {
      yield put(common.updateNotificationLoader(true));
      const response = yield Commonservice.clearNotifications();
      if (response) {
        yield put(common.updateNotificationRequest());
      }
      yield put(common.updateNotificationLoader(true));
    } catch (e) {
      yield put(common.updateNotificationLoader(false));
      const error = errorCode(e?.response?.status);
      yield put(
        cutomiser.updateDriveError({
          status: true,
          message: { head: 'Operation failed.', body: error, defaultMessage: 'Operation failed..' },
        }),
      );
    }
  } catch (e) {
    yield put(cutomiser.updateCommonLoader(false));
    const error = errorCode(e?.response?.status);
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: { head: 'Operation failed.', body: error, defaultMessage: 'Operation failed..' },
      }),
    );
  }
}

function* markSingleNotificationAsRead(action) {
  const { notificationId } = action.payload;
  try {
    yield Commonservice.readSingleNotification(notificationId);
  } catch (e) {
    const error = errorCode(e?.response?.status);
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: { head: 'Operation failed.', body: error, defaultMessage: 'Operation failed..' },
      }),
    );
    yield put(
      common.updateSingleNotificationReadErrorState({
        notificationId,
      }),
    );
  }
}

function* getSharedFile(action) {
  const { id, navigate } = action.payload;
  const tokenState = getFromLocalStorage('accessToken');
  try {
    const response = yield Commonservice.getShareFileId(id, tokenState);
    if (response?.data?.id) {
      const tokenResponse = yield Commonservice.getSharedEndPoint(
        response?.data?.id,
        id,
        tokenState,
      );
      if (tokenResponse?.data?.url) {
        yield put(common.updateShareLoader({ load: false, msg: true }));
      }
    }
  } catch (e) {
    if (e?.response?.status === 401) {
      navigate(`/fileshare/${id}`);
    } else {
      const error = errorCode(e?.response?.status);
      yield put(common.updateShareError(error));
      yield put(common.updateShareLoader({ load: false, msg: false }));
    }
  }
}

function* enableTwoFactorAuthForPortal(action) {
  const { portalId } = action.payload;
  try {
    yield Commonservice.enablePortalTwoFactorAuthentication(portalId);
    const modalPayload = { 'status': true, message: { body: "two factor authentication is now enabled for portal", defaultMessage: "two factor authentication is now enabled for portal", head: 'Portal settings' } }
    yield put(cutomiser.updateToastDialogModal(modalPayload));
  } catch (e) {
    console.log('e', e);
    const error = errorCode(e?.response?.status);
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Operation failed',
          body: error,
          defaultMessage: 'Error , Please try again',
        },
      }),
    );
  }
}

function* disableTwoFactorAuthForPortal(action) {
  const { portalId } = action.payload;
  try {
    yield Commonservice.disablePortalTwoFactorAuthentication(portalId);
    const modalPayload = { 'status': true, message: { body: "two factor authentication is now disabled for portal", defaultMessage: "two factor authentication is now disabled for portal", head: 'Portal settings' } }
    yield put(cutomiser.updateToastDialogModal(modalPayload));
  } catch (e) {
    console.log('e', e);
    const error = errorCode(e?.response?.status);
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Operation failed',
          body: error,
          defaultMessage: 'Error , Please try again',
        },
      }),
    );
  }
}

function* getTwoFactorAuthenticationForPortalStatus(action) {
  const { portalId } = action.payload;
  try {
    const response = yield Commonservice.getCurrentPortalSettingsService(portalId);
    yield put(common.updatePortal2FAState(response?.portal?.is_2fa));
  } catch (e) {
    console.log('e', e);
  }
}
function* getAuthQRCode(){
  try{
    const response = yield Commonservice.generateAuthQRCode();
    yield put(common.updateAuthQRCodeState(response))
  }
  catch(e){
    console.log("e",e)
  }
}

function* fetchProfileInfo() {
  let user = null
  try {
    user = yield Commonservice.getProfileInfo();
    yield put(cutomiser.UpdateProfileInfo(user));
  } catch (e) {
     yield put(cutomiser.UpdateProfileInfo(null));
  }
  return user;

}

function* disableTwoFactorAuthForUser(action){
  const {verificationCode} = action.payload

  const payload = {
    code_2fa : verificationCode
  }
  try{
    yield Commonservice.disableTwoFactorAuthFromUserProfileApi(payload)
    yield fetchProfileInfo()
    yield put(common.updateTwoFactorAuthenticationDisablePromptState({modalState : true}))
  }
  catch(e){
    let error 
    console.log("e",e.response.status)
    if(e?.response?.status === 403){
      error = 'two_fa_enabled_portal_user_cant_disable'
    }
    else if(e?.response?.status === 401){
      error = "Invalid verification code"
    }
    else {
      error = errorCode(e?.response?.status)
    }
    yield put(cutomiser.updateDriveError({ status: true, message: { head: 'two factor authentication', body:error, defaultMessage: "Error , Please try again" } }));
    console.log("e",e)
  }
}



function* enableTwoFactorAuthForUser(action){
  const {verificationCode} = action.payload
  const payload = {
    code_2fa : verificationCode
  }
  try{
    yield Commonservice.enableTwoFactorAuthFromUserProfileApi(payload)
    yield fetchProfileInfo()
    const modalPayload = { 'status': true, message: { body: "two factor authentication is now enabled", defaultMessage: "two factor authentication is now enabled", head: 'two factor authentication' } }
    yield put(cutomiser.updateToastDialogModal(modalPayload));
  }
  catch(e){
    let error
     if(e?.response?.status === 401){
      error = "Invalid verification code"
    }
    else {
       error = errorCode(e?.response?.status)
    }
    yield put(cutomiser.updateDriveError({ status: true, message: { head: "two factor authentication", body:error, defaultMessage: "Error , Please try again" } }));
    console.log("e",e)
  }
}



function* generateUserProfile2FAQrCode(action){
  yield put(cutomiser.updateCommonLoader(true))
  try{
    const response = yield Commonservice.generateUserProfileQR();
    yield put(common.updateAuthQRCodeState(response))
    yield put(cutomiser.updateCommonLoader(false))
  }
  catch(e){
    yield put(cutomiser.updateCommonLoader(false))
    console.log("e",e)
  }
}

function* getCopyCount(action){
  const {idArr} = action.payload
  try{
    const response = yield Commonservice.copyCount(idArr);
    if(response?.data){
      yield put(common.storeCopyCount({...response?.data}))
    }
  }catch(e){
    
    console.log(e)  
}
}
function* check2FaStateOfPortals(action) {
  try{
    const response = yield Commonservice.check2FaPortalEnabledApi()
    yield put(common.updatePortals2FaState(response?.data))
  }
  catch(e){
    console.log("e",e)
  }
}

/* eslint-disable camelcase */

function* commonSaga() {
  yield takeEvery(common.updateGetSearchPerm, getSearchPermission);
  yield takeEvery(common.updateFavFolderGet, getFavFolderItems);
  yield takeEvery(common.updateSendFavFolders, postFavFolders);
  yield takeEvery(common.updateDeleteFavs, sendDeleteFavs);
  yield takeEvery(common.updateFavSmartFolderGet, getFavSmartFolderItems);
  yield takeEvery(common.updateSendFavSmartFolders, postFavSmartFolders);
  yield takeEvery(common.updateNotificationRequest, notficationPopupFunction);
  yield takeEvery(common.updateReadNotifications, markAllAsReadNotifications);
  yield takeEvery(common.updateGetItemPath, fetchItemPath);
  yield takeEvery(common.updateClearNotifications, clearAllNotifications);
  yield takeEvery(common.updateSingleNotificationreadState, markSingleNotificationAsRead);
  yield takeEvery(common.updateSharedFile, getSharedFile);
  yield takeEvery(common.updateEnablePortal2fa, enableTwoFactorAuthForPortal);
  yield takeEvery(common.updateDisablePortal2fa, disableTwoFactorAuthForPortal);
  yield takeEvery(common.updateGet2FAPortalTrigger, getTwoFactorAuthenticationForPortalStatus);
  yield takeEvery(common.updateTriggerAuthQrCode,getAuthQRCode);
  yield takeEvery(common.updateDisableUserProfile2fa,disableTwoFactorAuthForUser);
  yield takeEvery(common.updateEnableUserProfile2fa,enableTwoFactorAuthForUser);
  yield takeEvery(common.updateGenerateUserProfileQr,generateUserProfile2FAQrCode);
  yield takeEvery(common.initiateCopyCount, getCopyCount);
  yield takeEvery(common.updateTriggerPortal2FaState,check2FaStateOfPortals);
}
export default commonSaga;
