import React from 'react';
import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/iCoordinator-logo_transp.svg';

const AuthLogo = () => {
  return (
    <div className="logo-header d-flex justify-content-center gap-2">
      <LogoWhiteIcon />
    </div>
  );
};

export default AuthLogo;
