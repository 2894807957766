
export function handleResponse(response) {
  return response;
}

export function handleError(err) {
  let error
  if(err?.response?.status === 401){
    error = { message :"Invalid username and password combination"}
  }
  else{
    error = {message : err}
  }
  return { error };
}
