import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import CustomizerReducer from './customizer/CustomizerSlice';
import CommonReducer from './common/CommonSlice';

import rootSaga from '../sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  common: CommonReducer,
});
export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
export default store;
