/* eslint-disable import/prefer-default-export */

// Function to add our give data into cache
const addDataIntoCache = (cacheName, url, response) => {
  // Converting our response into Actual Response form
  const data = new Response(JSON.stringify(response));

  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
};

// Function to delete our give cache
const deleteSpecificCache = (cacheName) => {
  if ('caches' in window) {
    caches.delete(cacheName).then((res) => {
      return res;
    });
  }
};

// Function to get single cache data
const getSingleCacheData = async (cacheName, url) => {
  if (typeof caches === 'undefined') return false;

  const cacheStorage = await caches.open(cacheName);
  const cachedResponse = await cacheStorage.match(url);
  const resp =
    cachedResponse?.ok &&
    cachedResponse.json().then((item) => {
      return item;
    });
  return resp;
};

export const AppCache = { addDataIntoCache, deleteSpecificCache, getSingleCacheData };
