import React from 'react';
import './loader.scss';
import { Spinner } from 'reactstrap';
import LogoDarkIcon from '../../assets/images/logos/iCoordinator-logo_transp.svg';

const Loader = () => (
  <div className="fallback-spinner">
    <div className="loading">
      <img src={LogoDarkIcon} alt="" width="130" className="logo-spinner" />
      <Spinner color="primary" />
    </div>
  </div>
);
export default Loader;
