/* eslint-disable prefer-destructuring */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import configData from '../../config/config';
import { setTokenLocally, RemoveTokenLocally, decryptJwt } from '../../helpers/commonHelpers';

const oAuthEndpoint = configData.BASE_URL + configData.oAuthTokenEndpoint;
// const oAuthGrantType  = configData.oAuthGrantType;
const oAuthRefreshTokenGrantType = configData.oAuthRefreshTokenGrantType;
const oAuthClientId = configData.oAuthClientId;
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const tokenData = decryptJwt(localStorage.getItem('accessToken'));
      config.headers.Authorization = `Bearer ${tokenData.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const refreshAuthLogic = (failedRequest) => {
  const tokenData = decryptJwt(localStorage.getItem('accessToken'));
  const data = {
    grant_type: oAuthRefreshTokenGrantType,
    client_id: oAuthClientId,
    refresh_token: tokenData.refreshToken,
  };

  const options = {
    method: 'POST',
    data,
    url: oAuthEndpoint,
  };

  return axios(options)
    .then(async (tokenRefreshResponse) => {
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;

      const payload = {
        accessToken: tokenRefreshResponse.data.access_token,
        refreshToken: tokenRefreshResponse.data.refresh_token,
        expireIn: tokenRefreshResponse.data.expires_in,
      };
      setTokenLocally(payload);
      return Promise.resolve();
    })
    .catch(() => {
      RemoveTokenLocally();
      window.location.reload();
    });
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  statusCodes: [401, 400],
  interceptNetworkError: true,
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
// );

export default axiosInstance;
