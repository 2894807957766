import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// import { ErrorBoundary } from 'react-error-boundary';
import Loadable from '../layouts/loader/Loadable';
import AuthGuard from '../components/authGurad/AuthGuard';

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
// const HomeLayout = Loadable(lazy(() => import('../layouts/HomeLayout')));
/***** Pages ****/

// const Dashboard1 = Loadable(lazy(() => import('../views/dashboards/Dashboard1')));
const Home = Loadable(lazy(() => import('../views/home/Home')));
const DashboardView = Loadable(lazy(() => import('../views/dashboard/dashboardView')));

const Admin = Loadable(lazy(() => import('../views/user/admin')));
const PortalMembersView = Loadable(
  lazy(() => import('../views/user/admin/portalmembers/portalMembersView')),
);
const PortalSettingView = Loadable(lazy(() => import('../views/user/admin/PortalSettingView')),);
const InvitationsView = Loadable(
  lazy(() => import('../views/user/admin/invitations/invitationView')),
);
const LabelView = Loadable(lazy(() => import('../views/user/admin/labels/labelView')));

const DriveView = Loadable(lazy(() => import('../views/user/drive/driveView')));
const ChooseDriveworkspace = Loadable(
  lazy(() => import('../views/user/drive/chooseDriveworkspaceView')),
);
const DriveWorkspaceDetailsView = Loadable(
  lazy(() => import('../views/user/drive/driveWorkspaceDetailsView')),
);
const RecyclebinView = Loadable(lazy(() => import('../views/user/drive/recyclebinView')));
const SmartfolderView = Loadable(lazy(() => import('../views/user/drive/smartfolderView')));
const DriveDefaultView = Loadable(lazy(() => import('../views/user/drive/driveDefaultView')));

const PeopleView = Loadable(lazy(() => import('../views/user/people/peopleView')));
const ProfileView = Loadable(lazy(() => import('../views/user/people/profileView')));
const GroupView = Loadable(lazy(() => import('../views/user/people/groupView')));
const PeopleWorkspaceDetailsView = Loadable(
  lazy(() => import('../views/user/people/peopleWorkspaceDetailsView')),
);
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../components/common/Error')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const TwoFactorAuthPage = Loadable(lazy(() => import('../views/auth/TwoFactorAuthView')));
const WorkspaceView = Loadable(lazy(() => import('../views/user/admin/workspaceView')));
const Chooseadminworkspace = Loadable(
  lazy(() => import('../views/user/admin/chooseadminworkspaceView')),
);
const SearchView = Loadable(lazy(() => import('../views/search/searchView')));
const AcceptInvite = Loadable(lazy(() => import('../views/auth/AcceptInvite')));
const DownloadFile = Loadable(lazy(() => import('../views/auth/downloadFile')));

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/home" /> },
      { path: 'fileshare/:id', element: <DownloadFile /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '/twofactauth', name : 'Home' , exact : true , element : <TwoFactorAuthPage/> },
      { path: '/home', name: 'Home', exact: true, element: <Home /> },
      { path: '/activity-history', name: 'Home', exact: true, element: <DashboardView /> },
      { path: '/profile', element: <ProfileView /> },
      {
        path: '/search',
        element: <SearchView />,
        children: [{ path: '/search/:filter', element: <SearchView /> }],
      },
      { path: '/user', element: <Navigate to="admin" /> },
      {
        path: '/user/admin',
        element: <Admin />,
        children: [
          { path: '/user/admin', element: <PortalMembersView /> },
          {
            path: '/user/admin/workspace/workspacedetails/:workspaceId/:name',
            element: <WorkspaceView />,
          },
          { path: '/user/admin/workspace', element: <Chooseadminworkspace /> },
          { path: '/user/admin/groups', element: <Chooseadminworkspace /> },
          { path: '/user/admin/portalmembers', element: <PortalMembersView /> },
          { path: '/user/admin/invitations', element: <InvitationsView /> },
          {path: '/user/admin/portalsettings',element : <PortalSettingView/>},
          { path: '/user/admin/labels', element: <LabelView /> },
          { path: '/user/admin/mygroups/:Id/:name', element: <GroupView /> },
        ],
      },
      {
        path: '/user/drive',
        element: <DriveView />,
        children: [
          { path: '/user/drive', element: <DriveDefaultView /> },
          { path: '/user/drive/workspace', element: <DriveDefaultView /> },
          {
            path: '/user/drive/workspace/workspacedetails/:menuId/:name/:key',
            element: <DriveWorkspaceDetailsView />,
          },
          { path: '/user/drive/recyclebin', element: <RecyclebinView /> },
          { path: '/user/drive/recyclebin/:menuId/:menuName', element: <RecyclebinView /> },
          { path: '/user/drive/smartfolder/:id/:type/:name', element: <SmartfolderView /> },
          { path: '*', element: <Navigate to="/auth/404" /> },
        ],
      },
      {
        path: '/user/members',
        element: <PeopleView />,
        children: [
          { path: '/user/members', element: <ChooseDriveworkspace /> },
          { path: '/user/members/workspace', element: <ChooseDriveworkspace /> },
          {
            path: '/user/members/workspace/workspacedetails/:workspaceId',
            element: <PeopleWorkspaceDetailsView />,
          },
          {
            path: '/user/members/workspace/workspacedetails/:id/:data',
            element: <PeopleWorkspaceDetailsView />,
          },
          { path: '*', element: <Navigate to="/auth/404" /> },
        ],
      },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'loginformik', element: <Login /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      {path: '2fa',element : <TwoFactorAuthPage/>},
      { path: 'accept_invite/:payload', element: <AcceptInvite /> },
      { path: 'fileshare/:id', element: <DownloadFile /> },
    ],
  },
];

export default ThemeRoutes;
