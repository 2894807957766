/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { handleResponse, handleError } from './response';
import config from '../config/config';
import { decryptJwt } from '../helpers/commonHelpers';

const oAuthEndpoint = config.BASE_URL + config.oAuthTokenEndpoint;
const oAuthGrantType = config.oAuthGrantType;
const oAuthClientId = config.oAuthClientId;
const resetPasswordUrl = config.apiServerDomain + config.resetPasswordEndpoint;

/** @param {string} resource */
/** @param {object} payload */
const signIn = (payload) => {
  return axios
    .post(`${oAuthEndpoint}`, {
      grant_type: oAuthGrantType,
      client_id: oAuthClientId,
      username: payload.username,
      password: payload.password,
    })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {object} payload : email */
const resetPassword = (payload) => {
  return axios
    .post(`${resetPasswordUrl}`, {
      email: payload.email,
    })
    .then(handleResponse)
    .catch(handleError);
};


const verify2FactorAuth = async (payload) => {
  const tokenData = decryptJwt(localStorage.getItem('accessToken'))
  const url =  `${config.apiServerDomain}/auth/verify-2fa`
  const response = await axios.post(url,payload,{
    headers : {
      "Authorization" : `Bearer ${tokenData?.accessToken}`
    }
  });
  return response
}



export const authService = {
  signIn,
  resetPassword,
  verify2FactorAuth
};
