import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Modal, ModalBody, ModalHeader, Row, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const ReportFeedbackModal = ({ modalStatus, toggle }) => {
  return (
    <>
      <Modal size="md" isOpen={modalStatus}>
        <ModalHeader toggle={() => toggle(false)}>
          <FormattedMessage id="Report" defaultMessage="Report" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <h2>
              {' '}
              <FormattedMessage id="Thank_you" defaultMessage="Thank you" description="Thank you" />
            </h2>
          </Row>
          <FormGroup>
            <Button size="sm" type="submit" color="primary">
              <FormattedMessage id="Done" defaultMessage="Done" description="Done" />
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </>
  );
};
ReportFeedbackModal.propTypes = {
  modalStatus: PropTypes.any,
  toggle: PropTypes.func,
};
export default ReportFeedbackModal;
