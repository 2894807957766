/* eslint-disable no-useless-escape */
import * as jwtEncrypt from 'jwt-token-encrypt';
import moment from 'moment';
import config from '../config/config';

// Encryption settings
const encryption = {
  key: 'AAAAAAAAAAAAAA',
  algorithm: 'aes-256-cbc',
};

const encryptJwt = async (accessToken, refreshToken) => {
  // Data that will be publicly available
  const publicData = {
    role: 'user',
  };
  // Data that will only be available to users who know encryption details.
  const privateData = {
    accessToken,
    refreshToken,
  };
  // JWT Settings
  const jwtDetails = {
    secret: 'icoordinator1234567', // to sign the token
    // Default values that will be automatically applied unless specified.
    // algorithm: 'HS256',
    // expiresIn: '12h',
    // notBefore: '0s',
    // Other optional values
    key: 'ThisIsMyAppISS', // is used as ISS but can be named iss too
  };
  const token = await jwtEncrypt.generateJWT(jwtDetails, publicData, encryption, privateData);
  return token;
};

const decryptJwt = (token) => {
  try {
    const decrypted = jwtEncrypt.readJWT(token, encryption);
    const tokenData = {
      accessToken: decrypted.data.accessToken,
      refreshToken: decrypted.data.refreshToken,
    };
    return tokenData;
  } catch (e) {
    return null;
  }
};

const setTokenLocally = (payload) => {
  encryptJwt(payload.accessToken, payload.refreshToken).then((resp) => {
    localStorage.setItem('accessToken', resp);
  });
  localStorage.setItem('scope', payload.scope);
  localStorage.setItem('expireIn', payload.expireIn);
};

const RemoveTokenLocally = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('expireIn');
  localStorage.removeItem('leftsideMenu');
  localStorage.removeItem('scope');
};

const SetSidebarMenuActiveNodes = (data) => {
  const keyOpenNodes = [];
  let str = '';
  data.split('/').forEach((item, i) => {
    if (i !== 0) {
      str += `/${item}`;
    } else {
      str += item;
    }
    keyOpenNodes.push(str);
  });

  localStorage.setItem('leftsideMenu', JSON.stringify(keyOpenNodes));
};

const randomIdGenerator = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return `${s4()}${s4()}${s4()}`;
};

const dateFormatter = (date, appdateformat, apptimeformat, dateentime, time) => {
  let datentimegenerated = '';
  function formatterStageOne() {
    let format = '';
    switch (appdateformat) {
      case 'YY-mm-dd':
        format = 'YY-MM-DD';
        break;
      case 'dd/mm/yyyy':
        format = 'DD/MM/YY';
        break;
      case 'yyyy-mm-dd':
        format = 'YYYY-MM-DD';
        break;
      case 'mm/dd/yyyy':
        format = 'MM/DD/YYYY';
        break;
      case 'yyyymmdd':
        format = 'YYYYMMDD';
        break;
      case 'dd.mm.yyyy':
        format = 'DD.MM.YYYY';
        break;
      default:
        format = null;
    }
    return format;
  }

  const dateConv = formatterStageOne();
  if (appdateformat !== '' && apptimeformat !== '') {
    if (dateentime !== '') {
      datentimegenerated = `${moment(dateentime).format(dateConv)} ${moment(dateentime).format(
        apptimeformat,
      )}`;
    }

    if (date !== '') {
      datentimegenerated = moment(date).format(dateConv);
    }
    if (time !== '') {
      datentimegenerated = moment(time).format(apptimeformat);
    }
  }
  return datentimegenerated;
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);

    fileReader.readAsDataURL(file);

    fileReader.onerror = (error) => reject(error);
  });
};

const convertKelvintoCelsius = (temp) => {
  const kelvinTemp = (temp - 273.15).toFixed(1);
  return kelvinTemp;
};

const getFromLocalStorage = (key) => {
  try {
    const items = localStorage.getItem(key);
    return items;
  } catch (e) {
    return e;
  }
};
const detectNameCollision = (type, name, filesStore) => {
  // eslint-disable-next-line consistent-return
  const file = filesStore.find((rec) => {
    if (rec.entity_type === type && rec.name === name) {
      return true;
    }
    return false;
  });
  return file;
};

const getModifiedDateString = (FileObj) => {
  let modAtDate;
  if (FileObj.lastModifiedDate != null) {
    modAtDate = FileObj.lastModifiedDate;
  } else {
    modAtDate = new Date(FileObj.lastModified);
  }
  const year = modAtDate.getFullYear();
  const month = `0${modAtDate.getMonth() + 1}`.slice(-2);
  const day = `0${modAtDate.getDate()}`.slice(-2);
  const hours = `0${modAtDate.getHours()}`.slice(-2);
  const minutes = `0${modAtDate.getMinutes()}`.slice(-2);
  const seconds = `0${modAtDate.getSeconds()}`.slice(-2);
  const offsetHours = `0${Math.floor(Math.abs(modAtDate.getTimezoneOffset()) / 60)}`.slice(-2);
  const offsetMinutes = `0${Math.abs(modAtDate.getTimezoneOffset()) % 60}`.slice(-2);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${
    modAtDate.getTimezoneOffset() > 0 ? '-' : '+'
  }${offsetHours}:${offsetMinutes}`;
};

const urlforcache = `http://${window?.location?.host}`;

const errorCode = (status) => {
  let errorState = '';
  if (status === 400) {
    errorState = 'Invalid_request';
  } else if (status === 404) {
    errorState = 'not_found';
  } else if (status === 403) {
    errorState = 'no_permission';
  } else if (status === 500) {
    errorState = 'server_error';
  } else if (status === 408) {
    errorState = 'time_out';
  } else if (status === 409) {
    errorState = 'duplicate_found';
  } else if (status === 401) {
    return 'unauthorized';
  } else if (status === 412) {
    return 'already_in_use';
  } else if (status === 423) {
    return 'locked file';
  } else {
    return 'error';
  }
  return errorState;
};

const generateNewName = (name, type, filestore) => {
  const regex = /^Copy\s[0-9]{1,4}\sof\s/;
  let counter = 1;
  let tmp;
  let substrArr;
  name = `Copy 1 of ${name}`;
  while (detectNameCollision(type, name, filestore)) {
    if (name.search(regex) > -1) {
      substrArr = name.match(/Copy\s[0-9]*\sof\s/);
      tmp = substrArr[0].match(/\d/g);
      counter = parseInt(tmp.join(''), 10) + 1;
      name = name.replace(regex, '');
      name = `Copy ${counter} of ${name}`;
      counter++;
    }
  }
  return name;
};

const addLockDaysDateString = () => {
  const newDate = new Date();
  const modAtDate = moment(newDate).add(7, 'day');

  const year = modAtDate.year();
  const month = `0${modAtDate.month() + 1}`.slice(-2);
  const day = `0${modAtDate.date()}`.slice(-2);
  const hours = `0${modAtDate.hours()}`.slice(-2);
  const minutes = `0${modAtDate.minutes()}`.slice(-2);
  const seconds = `0${modAtDate.seconds()}`.slice(-2);
  const offsetHours = `0${Math.floor(Math.abs(modAtDate.utcOffset()) / 60)}`.slice(-2);
  const offsetMinutes = `0${Math.abs(modAtDate.utcOffset()) % 60}`.slice(-2);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${
    modAtDate.utcOffset() < 0 ? '-' : '+'
  }${offsetHours}${offsetMinutes}`;
};

const downloadFile = (id, versionId) => {
  const local = getFromLocalStorage('accessToken');
  const decryptToken = decryptJwt(local);

  if (versionId && id && decryptToken) {
    window.open(
      `${config.apiServerDomain}/files/${id}/content?access_token=${decryptToken?.accessToken}&open_style=attachment&version=${versionId}`,
      '_blank',
    );
  } else if (id && decryptToken) {
    window.open(
      `${config.apiServerDomain}/files/${id}/content?access_token=${decryptToken?.accessToken}&open_style=attachment`,
      '_blank',
    );
  }
};

const downloadMultiple = (location) => {
  const local = getFromLocalStorage('accessToken');
  const decryptToken = decryptJwt(local);
  window.open(
    `${config.apiServerDomain}${location}&access_token=${decryptToken?.accessToken}`,
    '_blank',
  );
};

const shareLinkFile = (id, versionId) => {
  const local = getFromLocalStorage('accessToken');
  const decryptToken = decryptJwt(local);
  let sharelink;
  if (versionId && id && decryptToken) {
    sharelink = `${config.apiServerDomain}/files/${id}/content?access_token=${decryptToken?.accessToken}&open_style=attachment&version=${versionId}`;
  } else if (id && decryptToken) {
    sharelink = `${config.apiServerDomain}/files/${id}/content?access_token=${decryptToken?.accessToken}&open_style=attachment`;
  }
  return sharelink;
};

const sameDirectoryFlag = (keyData, menuIdData, driveSelectedItemData) => {
  let flag = false;
  if (
    keyData === 'workspace' &&
    driveSelectedItemData?.length > 0 &&
    driveSelectedItemData[0]?.original?.parent === null
  ) {
    flag = true;
  }
  if (keyData === 'recyclebin' && driveSelectedItemData?.length > 0) {
    flag = true;
  }
  if (
    driveSelectedItemData?.length > 0 &&
    driveSelectedItemData[0]?.original?.parent &&
    driveSelectedItemData[0]?.original?.parent?.id === parseInt(menuIdData, 10)
  ) {
    flag = true;
  }
  return flag;
};

const findWorkspaceDetails = (breadcrubDrive, key) => {
  let returrVal = {};
  if (key === 'folder' && breadcrubDrive) {
    const folder = breadcrubDrive?.split('<<');
    if (folder?.length > 0) {
      const x = folder[2]?.split('/');
      const workpaceId = x[0];
      returrVal = {
        parent: { id: folder[folder.length - 1] },
        workspaceId: workpaceId,
      };
    }
  } else if (key === 'workspace' && breadcrubDrive) {
    const workspace = breadcrubDrive?.split('>>');
    if (workspace.length > 0) {
      const x = workspace[2]?.split('<<');
      const workspaceId = x && x[1];
      returrVal = {
        parent: { id: 0 },
        workspaceId,
      };
    }
  }
  return returrVal;
};

const mailValidator = (email) => {
  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const ValidateFlag = validEmail.test(email);
  return ValidateFlag;
};
const headerNames = () => {
  return [
    { HeaderTitle: 'Name', accessor: 'name', id: 'name_123', isSelected: true },
    { HeaderTitle: 'Version', accessor: 'version', id: 'version_123', isSelected: true },
    {
      HeaderTitle: 'Comment',
      accessor: 'version_comment',
      id: 'versionCommnet_123',
      isSelected: false,
    },
    { HeaderTitle: 'Labels', accessor: 'entity_type', id: 'entity_type_123', isSelected: true },
    { HeaderTitle: 'Status', accessor: 'lock', id: 'lock_123', isSelected: true },
    { HeaderTitle: 'Size', accessor: 'size', id: 'size_123', isSelected: true },
    {
      HeaderTitle: 'Uploaded by',
      accessor: 'owned_by.name',
      id: 'owned_by_name_123',
      isSelected: true,
    },
    {
      HeaderTitle: 'Changed',
      accessor: 'content_modified_at',
      id: 'content_modified_123',
      isSelected: true,
    },
    { HeaderTitle: 'Uploaded', accessor: 'created_at', id: 'created_at_123', isSelected: false },
  ];
};

function convertFileSize(fileSizeInBytes) {
  let size = '';
  if (fileSizeInBytes >= 1073741824) {
    size = `${(fileSizeInBytes / 1073741824).toFixed(2)} GB`;
  } else if (fileSizeInBytes >= 1048576) {
    size = `${(fileSizeInBytes / 1048576).toFixed(2)} MB`;
  } else {
    size = `${(fileSizeInBytes / 1024).toFixed(2)} KB`;
  }
  return size;
}

function tableLocale(intl, text) {
  const localizedText = intl.formatMessage({ id: text });
  return localizedText;
}

function sortArrFileFolder(arr) {
  arr.sort((a, b) => {
    const s1 = a?.name?.replace(/(\d+)/g, '0000000000$1').replace(/0*(\d{10,})/g, '$1');
    const s2 = b?.name?.replace(/(\d+)/g, '0000000000$1').replace(/0*(\d{10,})/g, '$1');

    return s1?.localeCompare(s2, 'sv', { numeric: true });
  });
  return arr;
}

function sortNameFilter(a, b) {
  // Custom sorting function
  // Check if both names start with '0'
  const isAStartingWithZero = a?.startsWith('0');
  const isBStartingWithZero = b?.startsWith('0');
  let flag;
  // If only one starts with '0', it gets higher priority
  if (isAStartingWithZero !== isBStartingWithZero) {
    flag = isAStartingWithZero ? -1 : 1;
  } else {
    // If both start with '0' or neither starts with '0', sort alphabetically
    flag = a?.localeCompare(b, 'sv', { numeric: true });
  }
  return flag;
}

async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
  return null;
}

const findItemExist = (arr, itemName) => {
  const dirPaths = itemName?.fullPath?.split('/');
  if (dirPaths?.length > 2) return false;

  const doesExist = arr.some((ele) => {
    return ele.name === itemName.name && !itemName.isFile;
  });
  return doesExist;
};

async function readAllDirectoryEntries(directoryReader) {
  const entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  /* eslint-disable no-await-in-loop */
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  /* eslint-enable no-await-in-loop */
  return entries;
}

function isFirstElementNumericSpecialOrWhiteSpace(str) {
  // Check if the string is not empty
  if (str.length === 0) {
    return false;
  }

  // Get the first character of the string
  const firstChar = str.charAt(0);

  // Check if the first character is a numeric special character or whitespace
  const isNumericSpecialOrWhiteSpace = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/.test(
    firstChar,
  ); //eslint-disable-line

  return isNumericSpecialOrWhiteSpace;
}

function sortNameArrayFilter(arr) {
  function customSort(a, b) {
    // Custom sorting function
    // Check if both names start with '0'
    const isAStartingWithZero = a?.name?.startsWith('0');
    const isBStartingWithZero = b?.name?.startsWith('0');
    let flag;
    // If only one starts with '0', it gets higher priority
    if (isAStartingWithZero !== isBStartingWithZero) {
      flag = isAStartingWithZero ? -1 : 1;
    } else {
      // If both start with '0' or neither starts with '0', sort alphabetically
      flag = a?.name?.localeCompare(b?.name, 'sv', { numeric: true });
    }
    return flag;
  }
  // Sort the array using the custom sorting function
  const sortedArray = arr.sort(customSort);
  return sortedArray;
}

function sortArray(arr, name) {
  function customSort(a, b) {
    // Custom sorting function
    // Check if both names start with '0'
    const isAStartingWithZero = a[name]?.startsWith('0');
    const isBStartingWithZero = b[name]?.startsWith('0');
    let flag;
    // If only one starts with '0', it gets higher priority
    if (isAStartingWithZero !== isBStartingWithZero) {
      flag = isAStartingWithZero ? -1 : 1;
    } else {
      // If both start with '0' or neither starts with '0', sort alphabetically
      flag = a[name]?.localeCompare(b[name], 'sv', { numeric: true });
    }
    return flag;
  }
  // Sort the array using the custom sorting function
  const sortedArray = arr.sort(customSort);

  return sortedArray;
}

function sortNameArrayFilterfav(arr) {
  function customSort(a, b) {
    // Custom sorting function
    // Check if both names start with '0'
    const isAStartingWithZero = a?.title?.startsWith('0');
    const isBStartingWithZero = b?.title?.startsWith('0');
    let flag;
    // If only one starts with '0', it gets higher priority
    if (isAStartingWithZero !== isBStartingWithZero) {
      flag = isAStartingWithZero ? -1 : 1;
    } else {
      // If both start with '0' or neither starts with '0', sort alphabetically
      flag = a?.title?.localeCompare(b?.title, 'sv', { numeric: true });
    }
    return flag;
  }
  // Sort the array using the custom sorting function
  const sortedArray = arr.sort(customSort);
  return sortedArray;
}
const sizeSort = (a, b) => {
  let sortCntrlFlag;
  if (a && !b) {
    sortCntrlFlag = 1;
  } else if (b && !a) {
    sortCntrlFlag = -1;
  } else if (a && b) {
    sortCntrlFlag = a - b;
  } else {
    sortCntrlFlag = 1;
  }
  return sortCntrlFlag;
};
const createNewSelected = (selectedItem, key, keyItem) => {
  const newObj = { ...selectedItem };
  newObj[key] = keyItem;
  return newObj;
};

function removeObjectFromArray(arr, obj) {
  return arr.filter((item) => item.id !== obj.id);
}

export {
  setTokenLocally,
  RemoveTokenLocally,
  decryptJwt,
  SetSidebarMenuActiveNodes,
  randomIdGenerator,
  urlforcache,
  dateFormatter,
  convertBase64,
  convertKelvintoCelsius,
  getFromLocalStorage,
  detectNameCollision,
  getModifiedDateString,
  errorCode,
  generateNewName,
  addLockDaysDateString,
  downloadFile,
  shareLinkFile,
  sameDirectoryFlag,
  findWorkspaceDetails,
  mailValidator,
  headerNames,
  convertFileSize,
  sortNameArrayFilter,
  downloadMultiple,
  tableLocale,
  sortArrFileFolder,
  sortNameFilter,
  readEntriesPromise,
  findItemExist,
  isFirstElementNumericSpecialOrWhiteSpace,
  readAllDirectoryEntries,
  sortArray,
  sortNameArrayFilterfav,
  sizeSort,
  createNewSelected,
  removeObjectFromArray,
};
