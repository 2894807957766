/* eslint-disable camelcase */
import { createContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setSession } from './Jwt';
import { authService } from '../../service/authService';
import { updateSignout } from '../../store/customizer/CustomizerSlice';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  TWOFACTORLOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithTwoFactorAuthentication: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  createUserWithEmailAndPassword: () => Promise.resolve(),
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispacth = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: null,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signInWithEmailAndPassword = async (email, password) => {
    const response = await authService.signIn({ username: email, password });
    if (response && response.data) {
      const { access_token, refresh_token, expires_in, token_type, is_2fa, is_portal_2fa, scope } =
        response.data;
      const user = {
        access_token,
        refresh_token,
        expires_in,
        token_type,
        is_2fa,
        is_portal_2fa,
        scope,
      };

      setSession(access_token, expires_in, refresh_token, scope);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
    return response;
  };

  const resetPassword = async (email) => {
    const response = await authService.resetPassword({ email });
    return response;
  };

  const logout = async () => {
    setSession(null, null, null, null);
    dispatch({ type: 'LOGOUT' });
    reduxDispacth(updateSignout(true));
  };

  const signInWithTwoFactorAuthentication = async (payload) => {
    try{
      if (payload && payload?.data) {
        const { access_token, refresh_token, expires_in, token_type, is_2fa, is_portal_2fa, scope } =payload.data;
        const user = {
          access_token,
          refresh_token,
          expires_in,
          token_type,
          is_2fa,
          is_portal_2fa,
          scope,
        };
        setSession(access_token, expires_in, refresh_token, scope);
        dispatch({
          type: 'TWOFACTORLOGIN',
          payload: {
            user,
          },
        });
      }
    }
    catch(e){
      console.log("Error",e)
    }
    
    return payload;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signInWithEmailAndPassword,
        signInWithTwoFactorAuthentication,
        logout,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.node,
};
export { AuthContext, AuthProvider };
