/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */

import AxiosInstance from '../components/jwt/axios';
import config from '../config/config';

const axios = AxiosInstance;
const getLanguageCache = () => {
  const languageCacheUrl = `${config.NODE_API_URL}/mylanguage`;
  const portal = axios.get(languageCacheUrl).then((resp) => {
    return resp;
  });
  console.log(portal);
};

const getCacheWithName = async (cacheName, userid) => {
  const cacheUrl = `${config.NODE_API_URL}/appcache/${userid}/${cacheName}`;
  const cacheResponse = await axios.get(cacheUrl).then((resp) => {
    return resp?.data ? resp?.data[0] : null;
  });
  return cacheResponse;
};

const postCache = async (content) => {
  const cacheUrl = `${config.NODE_API_URL}/appcache/add`;
  const cacheResponse = await axios.post(cacheUrl, content).then((resp) => {
    return resp;
  });
  return cacheResponse;
};

const putCache = async (userid, cache, cacheName) => {
  const cacheUrl = `${config.NODE_API_URL}/appcache/update/${userid}/${cacheName}`;
  const cacheResponse = await axios.post(cacheUrl, cache).then((resp) => {
    return resp;
  });
  return cacheResponse;
};

export const ServerCache = {
  getLanguageCache,
  getCacheWithName,
  postCache,
  putCache,
};
